import Model from './model';
import LocalCurrencyFilter from '@/application/mixins/validLocalCurrency.js';

export class OrderLine extends Model {
  id = 0;
  productId = 0;
  image = '';
  sku = '';
  name = '';
  description = '';
  price = 0;
  localPrice = null;
  quantity = 0;
  lineTotal = 0;
  localLineTotal = 0;
  addOns = {};

  mapForRequest() {
    return { ...this };
  }

  mapResponse(data) {
    data.addOns = data['add-ons'];
    data.addOns.products.map(product => {
      return {
        ...product,
        price: product.price,
        localPrice: LocalCurrencyFilter(product.localPrice),
      };
    });
    data.addOns.totalPrice = data.addOns.totalPrice ;
    data.localPrice = LocalCurrencyFilter(data.localPrice);
    return super.mapResponse(data);
  }
}

export default class ShoppingCart extends Model {
  id = 0;
  amount = 0;
  orderLines = [];
  statusId = 0;

  mapForRequest() {
    return {
      amount: this.amount,
      orderLines: this.orderLines.map(item => item.mapForRequest()),
    };
  }

  mapResponse(data) {
    data = super.mapResponse(data);
    data.orderLines = data.orderLines.map(item => new OrderLine().mapResponse(item));
    return data;
  }
}
